import withLogin from "../components/Login/withLogin";
import { useEffect } from "react";
import { useRouter } from "next/router";
import withAuth from "../components/decorator/WithAuth";
import { flowTypes } from "../components/Login/LoginConstants";
import Head from "next/head";

const { INSCHOOL, INSCHOOL_BANK_UPDATE, WALLET, REFI } = flowTypes;

const LoginPage = ({ initLogin, auth }) => {
  const router = useRouter();
  useEffect(() => {
    handleLogin();
  }, []);
  const handleLogin = () => {
    const callbackUrl = router.query.callbackUrl;
    const url = new URL("https://test.com/" + callbackUrl);
    let flowType;
    if (callbackUrl?.includes("wallet")) {
      flowType = WALLET;
    } else if (callbackUrl?.includes("refinance")) {
      flowType = REFI;
    } else if (callbackUrl?.includes("update-bank-details")) {
      flowType = INSCHOOL_BANK_UPDATE;
    } else {
      flowType = url.searchParams.get("user_context");
    }
    if (auth?.isValid) {
      router.push(callbackUrl);
    } else {
      initLogin(false, "", flowType || INSCHOOL);
    }
  };
  return (
    <Head>
      <title>Login/Register | Leap Finance</title>
    </Head>
  );
};

export default withAuth(withLogin(LoginPage), false);
